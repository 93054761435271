exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-contact-examples-js": () => import("./../../../src/pages/contact/examples.js" /* webpackChunkName: "component---src-pages-contact-examples-js" */),
  "component---src-pages-contact-file-upload-js": () => import("./../../../src/pages/contact/file-upload.js" /* webpackChunkName: "component---src-pages-contact-file-upload-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-thanks-js": () => import("./../../../src/pages/contact/thanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */),
  "component---src-pages-donors-js": () => import("./../../../src/pages/donors.js" /* webpackChunkName: "component---src-pages-donors-js" */),
  "component---src-pages-giving-js": () => import("./../../../src/pages/giving.js" /* webpackChunkName: "component---src-pages-giving-js" */),
  "component---src-pages-grants-js": () => import("./../../../src/pages/grants.js" /* webpackChunkName: "component---src-pages-grants-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-memorial-js": () => import("./../../../src/pages/memorial.js" /* webpackChunkName: "component---src-pages-memorial-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-philanthropy-2-js": () => import("./../../../src/pages/philanthropy2.js" /* webpackChunkName: "component---src-pages-philanthropy-2-js" */),
  "component---src-pages-philanthropy-js": () => import("./../../../src/pages/philanthropy.js" /* webpackChunkName: "component---src-pages-philanthropy-js" */),
  "component---src-pages-publications-js": () => import("./../../../src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */),
  "component---src-pages-recipients-js": () => import("./../../../src/pages/recipients.js" /* webpackChunkName: "component---src-pages-recipients-js" */),
  "component---src-pages-scholarships-js": () => import("./../../../src/pages/scholarships.js" /* webpackChunkName: "component---src-pages-scholarships-js" */),
  "component---src-pages-tags-index-js": () => import("./../../../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-pages-volunteers-js": () => import("./../../../src/pages/volunteers.js" /* webpackChunkName: "component---src-pages-volunteers-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-product-page-js": () => import("./../../../src/templates/product-page.js" /* webpackChunkName: "component---src-templates-product-page-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

